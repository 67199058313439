<template>
  <footer>
    <v-container class="footer-container">
      <div class="the-main-footer-content">
        <div
          v-for="item in listOfFooterMenu"
          :key="item.group"
          class="column information-list -is-2"
        >
          <div class="title-group">
            {{ item.group }}
          </div>
          <div v-if="item.items.length > 0" class="menu-list">
            <div
              v-for="({ url, name }, index) in item.items"
              :key="index"
              class="menu"
            >
              <a class="link" target="_blank" :href="url">
                {{ name }}
              </a>
            </div>
          </div>
          <div v-else class="apple-premium-partner">
            <img
              src="@/assets/premium-partner-logo.svg"
              alt="partner-logo"
              class="partner-logo"
            />
            <img
              src="@/assets/authorized-service-provider.webp"
              alt="authorized-service-provider-logo"
              class="authorized-service-provider"
            />
            <img
              src="@/assets/authorized-education-specialist.webp"
              alt="authorized-education-specialist-logo"
              class="authorized-education-specialist"
            />
          </div>
        </div>

        <div class="dp-block dp-lg-none">
          <FooterAccordionItem
            v-for="(item, index) in listOfFooterMenu"
            :key="item.group"
            :class="{ 'is-first': index == 0 }"
          >
            <template v-slot:title>
              {{ item.group }}
            </template>

            <template v-slot:content>
              <div class="menu-list">
                <div
                  v-for="({ url, name }, index) in item.items"
                  :key="index"
                  class="menu"
                >
                  <a class="link" target="_blank" :href="url">
                    {{ name }}
                  </a>
                </div>
              </div>
            </template>
          </FooterAccordionItem>
        </div>

        <!-- Apple Premium Partner -->
        <div class="dp-block dp-lg-none">
          <div class="apple-premium-partner">
            <img
              src="@/assets/premium-partner-logo.webp"
              alt="partner-logo"
              class="partner-logo"
            />
            <img
              src="@/assets/authorized-service-provider.webp"
              alt="authorized-service-provider-logo"
              class="authorized-service-provider"
            />
            <img
              src="@/assets/authorized-education-specialist.webp"
              alt="authorized-education-specialist-logo"
              class="authorized-education-specialist"
            />
          </div>
        </div>
      </div>

      <div class="the-main-footer-contact">
        <div class="footer-social-group">
          <a
            href="https://com7.co/40McsYl"
            class="icon-social-facebook"
          >
            <img
              src="@/assets/icon/studio7/icon-social-facebook.webp"
              alt="icon social facebook"/></a
          ><a
            href="https://com7.co/3SQR3Lq"
            class="icon-social-instagram"
            ><img
              src="@/assets/icon/studio7/icon-social-instagram.webp"
              alt="icon social instagram"/></a
          ><a href="https://com7.co/46l5I4I" class="icon-social-line"
            ><img
              src="@/assets/icon/studio7/icon-social-line.webp"
              alt="icon social line"/></a
          ><a
            href="https://com7.co/3SRZk1S"
            class="icon-social-tiktok"
            ><img
              src="@/assets/icon/studio7/icon-social-tiktok.webp"
              alt="icon social tiktok"/></a
          ><a
            href="https://com7.co/3SRZkPq"
            class="icon-social-youtube"
            ><img
              src="@/assets/icon/studio7/icon-social-youtube.webp"
              alt="icon social youtube"/></a
          ><a
            href="https://com7.co/40O6PJ8"
            class="icon-social-twitter"
            ><img
              src="@/assets/icon/studio7/icon-social-twitter.webp"
              alt="icon social twitter"
          /></a>
        </div>

        <!-- <div class="footer-payment-group">
          <img
            src="@/assets/icon/studio7/icon-payment-visa.webp"
            alt="icon-payment-visa"
          /><img
            src="@/assets/icon/studio7/icon-payment-master-card.webp"
            alt="icon-payment-master-card"
          /><img
            src="@/assets/icon/studio7/icon-payment-jcb.webp"
            alt="icon-payment-jcb"
          /><img
            src="@/assets/icon/studio7/icon-payment-thai-qr.webp"
            alt="icon-payment-thai-qr"
          />
          <img
            src="@/assets/icon/studio7/icon-payment-alipay.webp"
            alt="icon-payment-alipay"
          />

          <img
            src="@/assets/icon/studio7/icon-payment-amex.webp"
            alt="icon-payment-amex"
          />
          <img
            src="@/assets/icon/studio7/icon-payment-thai.webp"
            alt="icon-payment-thai"
          />
        </div> -->

        <div class="footer-copy-right">
          <div class="divider"></div>
          <div>
            © 2024, Com7 Public Company Limited All rights reserved.
          </div>
          <div class="divider mb-0"></div>
        </div>
      </div>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: 'FooterFullStudio7',

  components: {
    FooterAccordionItem: () =>
      import('@/components/studio7/FooterAccordionItem.vue'),
  },

  computed: {},

  data() {
    return {
      listOfFooterMenu: [
        {
          group: 'Shop',
          items: [
            {
              name: 'Mac',
              url:
                'https://www.studio7thailand.com/en/view-all/apple-mac-series',
            },
            {
              name: 'iPad',
              url:
                'https://www.studio7thailand.com/en/view-all/all-apple-ipad-series',
            },
            {
              name: 'iPhone',
              url:
                'https://www.studio7thailand.com/en/view-all/apple-iphone-series',
            },
            {
              name: 'Watch',
              url:
                'https://www.studio7thailand.com/en/view-all/apple-watch-series',
            },
            {
              name: 'มิวสิก',
              url:
                'https://www.studio7thailand.com/en/view-all/apple-airpods-series',
            },
            {
              name: 'TV และบ้าน',
              url:
                'https://www.studio7thailand.com/en/view-all/apple-tv-series',
            },
            {
              name: 'อุปกรณ์เสริม',
              url:
                'https://www.studio7thailand.com/en/view-all/apple-accessories',
            },
          ],
        },
        {
          group: 'บริการ',
          items: [
            {
              name: 'ค้นหาร้านค้า',
              url: 'https://studio7thailand.com/store-locator',
            },
            {
              name: 'โปรโมชั่นบัตรเครดิต',
              url:
                'https://studio7thailand.com/pages/promotion-installment',
            },
            {
              name: 'In-Store Classes',
              url:
                'https://studio7thailand.com/pages/in-store-classes',
            },
          ],
        },
        {
          group: 'Studio 7',
          items: [
            {
              name: 'เกี่ยวกับ Studio7thailand',
              url: 'https://studio7thailand.com/pages/help-support',
            },
            {
              name: 'ข้อกำหนดและเงื่อนไข',
              url: 'https://studio7thailand.com/pages/terms-of-use',
            },
            {
              name: 'ร่วมงานกับเรา',
              url: 'https://www.comsevencareer.com/',
            },
          ],
        },
        {
          group: 'ข้อมูล',
          items: [
            {
              name: 'คำถามที่พบบ่อย',
              url:
                'https://studio7thailand.com/pages/how-to-register',
            },
            {
              name: 'นโยบายความเป็นส่วนตัว',
              url: 'https://studio7thailand.com/pages/privacy',
            },
            {
              name: 'บริการจัดส่งสินค้า และ รับสินค้า',
              url:
                'https://studio7thailand.com/pages/delivery-condition',
            },
          ],
        },
        {
          group: 'Apple Premium Partner',
          items: [],
        },
      ],
    }
  },
}
</script>

<style lang="stylus" scoped>
.footer-container {
    padding: 32px 12px 0
    border-top: 1px solid rgb(208, 208, 208)

    @media screen and (min-width: 1024px) {
        padding: 64px 50px 0;
        margin-top: 24px
    }
}

.the-main-footer-content {
    display: flex;
    flex-direction: column;

    font-size: 14px;

    @media screen and (min-width: 1024px) {
        // padding: 32px 0 16px 0;
        flex-direction: row;
    }

    > .column:not(:last-child) {
        display: none
        @media screen and (min-width: 1024px) {
            width: 33.33%;
            display: block;
        }
    }

    > .studio7-info {
        display: none;

        @media screen and (min-width: 1024px) {
            display: block;
        }

        .site-logo {
            height: $site-logo-height-desktop;

            > .img {
                height: 100%;
            }
        }

        > .label {
            font-weight: 500;
            line-height: 1.4;
            color: $color-theme-white-75;

            @media screen and (min-width: 1024px) {
                margin-top: 16px;
            }
        }
    }

    .title-group {
        margin-bottom: 30px;

        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;

        @media screen and (min-width: 1024px) {
            font-family: "SF Pro Display";
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.0225rem;
            line-height: 1.6875rem !important;
        }
    }

    .menu-list {
        display: flex;
        flex-direction: column;
    }

    .menu {
        margin-bottom: 16px;

        font-size: 12px;
        font-weight: 600;
        line-height: 1.6;

        @media screen and (min-width: 1024px) {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 16px;
            padding-right: 15px;
        }

        > .link {
            color: black
            cursor: pointer;
            font-size: 0.875rem;
            line-height: 1.3125rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.6px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                text-underline-offset: 0.1875rem;
            }
        }
    }

    .apple-premium-partner {
        padding: 56px 0
        display: flex;
        flex-direction: column;
        margin: 0 30%

        @media screen and (min-width: 1024px) {
            padding: 0;
            margin: 0;
        }
    }

    .partner-logo {
        margin-bottom: 1rem;
        width: 100px;
        height: auto;
        object-fit: contain;
    }

    .authorized-service-provider,
    .authorized-education-specialist {
        margin-bottom: 1rem;
        width: 130px;
        height: auto;
        object-fit: contain;
    }
}

.the-main-footer-contact {
    padding: 0

    @media screen and (min-width: 1024px) {
        padding: 64px 0
    }

    .footer-social-group {
        line-height: 1.15;
        font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        font-size: 16px;
        text-size-adjust: 100%;
        font-weight: 500;
        color: rgb(0, 0, 0);
        box-sizing: border-box;
        overflow-wrap: break-word;
        justify-content: center;
        align-items: center;
        display: flex;
        gap: 1.5rem;
        margin-bottom: 24px

        @media screen and (min-width: 1024px) {
            justify-content: flex-end;
        }

        > a {
            justify-content: center;
            align-items: center;
            display: flex;
            width: 20px;
            height: 20px;

            > img {
                object-fit: cover;
                object-position: center center;
                max-width: 18px;
                max-height: 18px;
            }

            @media screen and (min-width: 1024px) {
              //
            }
        }
    }

    .footer-payment-group {
        display: flex;
        justify-content: center;
        line-height: 1.15;
        margin: 15px 0px;
        padding-top: 17px;

        @media screen and (min-width: 1024px) {
            justify-content: flex-end;
            margin: 0.9375rem 0px;
            padding-top: 2.25rem;
        }

        > img {
            width auto
            height: 19px;
            line-height: 1.15;
            font-size: 16px;

            @media screen and (min-width: 1024px) {
                height: 24px;
            }
        }
    }

    .footer-copy-right {
        line-height: 1.15;
        font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        text-size-adjust: 100%;
        color: rgba(18, 18, 18, 0.75);
        font-size: 0.6875rem;
        font-weight: 400;
        letter-spacing: 0.7px;
        text-align: center;

        @media screen and (min-width: 1024px) {
            text-align: right;
        }
    }

    .divider {
        margin: 2rem 0;
        border-top: 1px solid #d0d0d0;
        border-color: #7d7d7d;

        @media screen and (min-width: 1024px) {
            display: none;
        }
    }
}

.is-first {
    border-top: 0.5px solid #7d7d7d;
}
</style>
